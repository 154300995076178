import React from 'react'
import Layout from '../components/layout'

export default () => {
  
  return (
    <Layout title="Bio">
      <h1>
        Bio
      </h1>
      <article>
        <p>Terminal Sound System is Australia-based musician/producer Skye Klein and an army of robots, keeping time for Armageddon.</p>
        <p>Starting as an industrial dub live PA set in the last days of the 20th century, Terminal Sound System has grown and morphed through glitchy electronica, experimental drum’n’bass, electronic post-rock and deconstructed post-industrial gothic-folk (usually several at once) over the course of ten albums.</p>
        <p>Terminal Sound System’s current live actions blend damaged occult folk narratives with passages of pulsating electronic rhythm and heavy ambient atmospherics.</p>
        <p>Terminal Sound System has released through Relapse Records (US), Hive Records (US), Embryo Recordings (AU), Extreme Music (AU) and Denovali Records (DE) and is currently signed to Denovali.</p> 
      </article>
    </Layout>
  )
}
